import RuntimeConfig from './Runtime';
import CORSRequest from './CORSRequest';
import Log from './Log';
import { Modes } from './Modes';

interface IApiCallConfig {
  params?: {};
  headers?: {};
  data?: {};
  onload: (xhr: XMLHttpRequest) => void;
  onerror: (xhr: XMLHttpRequest) => void;
}

interface IPaymentEstimation {
  id: string;
  monthlyAmount: number;
  amountFinanced: number;
  downPayment: number;
  apr: number;
}

export interface IOnlineUrlApiResponse {
  url: string;
  errorCode?: string;
  message?: string;
}

export interface IPaymentEstimationApiResponse extends Array<IPaymentEstimation> {
  errorCode?: string;
  message?: string;
}
export interface IApiError {
  errorCode: string;
  message: string;
}

declare var __IS_UNSAFE__: boolean;
declare var __API_DEMO_URL__: string;
declare var __API_PROD_URL__: string;

const isUnsafe = __IS_UNSAFE__ || false;

const API_URLS = {
  [Modes.DEMO_MODE]: __API_DEMO_URL__,
  [Modes.PRODUCTION_MODE]: __API_PROD_URL__,
};

function getBaseUrl() {
  return API_URLS[RuntimeConfig.getMode()] || API_URLS[Modes.DEMO_MODE];
}

export function setApiUrl(mode: string, url: string) {
  if (isUnsafe) {
    API_URLS[mode] = url;
  }
}

export class ApiError extends Error {
  private status: number;
  private errorCode: string;
  private originalMessage: string;
  constructor(message: string, originalMessage: string, status: number, errorCode: string) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.errorCode = errorCode;
    this.originalMessage = originalMessage;
  }

  public getStatus() {
    return this.status;
  }

  public getErrorCode() {
    return this.errorCode;
  }

  public getOriginalMessage() {
    return this.originalMessage;
  }
}

export function getPaymentEstimationResponseHandler(
  _error,
  xhr: XMLHttpRequest,
  cb,
  json: IApiError | IPaymentEstimationApiResponse
) {
  let message: string = '';
  let errorCode: string = '';
  if (json !== void 0) {
    if (json.message !== void 0) {
      message = json.message;
    }
    if (json.errorCode) {
      errorCode = json.errorCode;
    }
  }
  switch (xhr.status) {
    case 200:
    case 201:
      return cb(null, json);
    case 422:
      return cb(
        new ApiError('Required request body is missing or No amounts provided', message, xhr.status, errorCode)
      );
    case 500:
      return cb(new ApiError('Internal Server Error', message, xhr.status, errorCode));
    default:
      return cb(new ApiError(message, message, xhr.status, errorCode));
  }
}

export function getOnlineUrlResponseHandler(_error, xhr: XMLHttpRequest, cb, json: IApiError | IOnlineUrlApiResponse) {
  let message: string = '';
  let errorCode: string = '';
  if (json !== void 0) {
    if (json.message !== void 0) {
      message = json.message;
    }
    if (json.errorCode) {
      errorCode = json.errorCode;
    }
  }
  switch (xhr.status) {
    case 200:
    case 201:
      return cb(null, json);
    case 404:
      return cb(new ApiError('Location not found', message, xhr.status, errorCode));
    case 500:
      return cb(new ApiError('Internal Server Error', message, xhr.status, errorCode));
    default:
      return cb(new ApiError(message, message, xhr.status, errorCode));
  }
}

export function getTermsResponseHandler(_error, xhr: XMLHttpRequest, cb, json: IApiError) {
  switch (xhr.status) {
    case 200:
    case 201:
      return cb(null, json);
    default:
      return cb(
        new ApiError('An occured while loading terms', 'An occured while loading terms', xhr.status, 'errorCode')
      );
  }
}

export function getPrescreenResponseHandler(_error, xhr: XMLHttpRequest, cb, json: IApiError) {
  switch (xhr.status) {
    case 200:
    case 201:
      return cb(null, json);
    default:
      return cb(
        new ApiError(
          'An error occured while getting pre-screen response',
          'An error occured while getting pre-screen response',
          xhr.status,
          'errorCode'
        )
      );
  }
}

const api = {
  getPaymentEstimation: {
    method: 'put',
    url: 'purchase-service/api/v1/payment-estimation',
    params: {
      timeZone: 'TimeZone',
      location: 'Location',
      representative: 'Representative',
      representativeEmail: 'RepresentativeEmail',
      ro: 'RO',
    },
    response: getPaymentEstimationResponseHandler,
  },
  getOnlineUrl: {
    method: 'put',
    url: 'purchase-service/api/v1/online-link',
    response: getOnlineUrlResponseHandler,
    data: () => {
      return {
        location: RuntimeConfig.getLocation(),
        representativeEmail: RuntimeConfig.getRepresentativeEmail(),
      };
    },
  },
  getTerms: {
    method: 'get',
    url: 'purchase-service/api/v1/payment-estimation/terms',
    params: {
      location: 'Location',
    },
    response: getTermsResponseHandler,
  },
  getPreScreenResult: {
    method: 'get',
    url: 'ps1-gateway-service/api/v1/auto-prequal/sdk',
    params: {
      'prescreen-id': 'PrescreenId',
    },
    response: getPrescreenResponseHandler,
  },
};

export function parseResponse(xhr: XMLHttpRequest, methodCallback, callerCallback) {
  const responseText = xhr.responseText;
  let error: {
    status?: number;
    message: string;
  };
  let json: any;
  try {
    json = JSON.parse(responseText);
  } catch (e) {
    Log.error('error parsing response as JSON');
  }

  if (!json) {
    error = xhr ? { message: 'HTTP Error: ' + xhr.status } : { message: 'Unknown error' };
  } else if (json.errors) {
    error = json;
  }

  if (error) {
    error.status = xhr.status;
    return methodCallback(error, xhr, callerCallback);
  }

  return methodCallback(null, xhr, callerCallback, json);
}

export function parseUrl(url: string): string {
  function replacer(match: string, _colon1: string, key: string, _colon2: string, _offset: number, _str: string) {
    if (typeof RuntimeConfig['get' + key] === 'function') {
      const value = RuntimeConfig['get' + key]();
      if (value) {
        return RuntimeConfig['get' + key]();
      }
    }
    return match;
  }

  return url.replace(/(:)([A-Za-z0-9]+)(:)/g, replacer);
}

export function apiClient(methodName: string, config: any) {
  if (!RuntimeConfig.getInitRan()) {
    throw new Error('SUNBIT.init must be called before calling an API method');
  }

  if (api[methodName] === void 0) {
    throw new Error('No such method in api');
  }

  if (config.cb === void 0) {
    throw new Error('Callback method must be set');
  }

  const apiCall = api[methodName];
  const apiCallParams = {};

  if (apiCall.params) {
    Object.keys(apiCall.params).forEach((name) => {
      apiCallParams[name] = RuntimeConfig['get' + apiCall.params[name]].call(RuntimeConfig);
    });
  }

  const apiCallConfig: IApiCallConfig = {
    params: apiCallParams,
    headers: {
      'sunbit-key': RuntimeConfig.getSunbitKey(),
    },
    onload(xhr) {
      parseResponse(xhr, api[methodName].response, config.cb);
    },
    onerror(xhr) {
      parseResponse(xhr, api[methodName].response, config.cb);
    },
  };

  if (config.data === void 0 && api[methodName].data !== void 0) {
    apiCallConfig.data = api[methodName].data();
  }

  if (config.data !== void 0) {
    apiCallConfig.data = config.data;
  }

  try {
    const request = new CORSRequest(apiCall.method, getBaseUrl() + parseUrl(apiCall.url), apiCallConfig);
    request.send();
  } catch (e) {
    Log.error('Could not open request', e.message);
  }
}
