import Model from './Model';
import { Modes } from './Modes';

declare var __IS_DEV__: boolean;
declare var __VERSION__: string;

export default new Model({
  Mode: Modes.SANDBOX_MODE,
  SunbitKey: '',
  Location: '',
  Representative: '',
  RepresentativeEmail: '',
  TimeZone: '',
  InitRan: false,
  IsDev: __IS_DEV__, // this var will be replaced by webpack depending on the env type
  Terms: null,
  RO: '',
  PrescreenId: '',
  PrescreenCustomerId: '',
  Locale: 'en-us',
  V2: false,
  Version: __VERSION__, // this var will be replaced by webpack depending on the env type
}) as { [x: string]: any };
