export function encode(params) {
  const pairs = [];
  Object.keys(params)
    .sort()
    .forEach(function(key) {
      const value = params[key];

      if (value === null) {
        pairs.push(key);
        return;
      }

      if (!value) {
        return;
      }

      pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    });
  return pairs.join('&');
}

export function decode(str:string, strict?:boolean) {
  if (strict === void 0) {
    strict = false;
  }
  const data = {};
  if (str === '') {
    return data;
  }
  const pairs = str.split('&');
  for (const pair of pairs) {
    const splittedPair = pair.split('=', 2);
    const key = decodeURIComponent(splittedPair[0]);
    if (strict && Object.prototype.hasOwnProperty.call(data, key)) {
      throw new URIError('Duplicate key: ' + key);
    }
    data[key] =
      splittedPair.length === 2 ? decodeURIComponent(splittedPair[1]) : null;
  }
  return data;
}

export function addParamsToUrl(url:string, params:string | {}) {
  const encodedParams = typeof params === 'string' ? params : encode(params);
  const response = encodedParams
    ? url + (url.indexOf('?') !== -1 ? '&' : '?') + encodedParams
    : url;

  return response;
}
