import RuntimeConfig from './Runtime';

export function Logger() {
  const isDev = RuntimeConfig.getIsDev();
  const logger: Partial<typeof console> = {};
  for (const method in console) {
    if (typeof console[method] === 'function') {
      if (!isDev && ['log', 'debug'].indexOf(method) >= 0) {
        logger[method] = () => {}; // tslint:disable-line:no-empty
        continue;
      }
      logger[method] = console[method].bind(console);
    }
  }
  return logger;
}

const log = Logger();

const colorsBrowser = {
  'debug': ['%cdebug:', 'color:green;font-weight:bold;'],
  'info': ['%cdebug:', 'color:green;font-weight:bold;'],
  'warn': ['%cdebug:', 'color:green;font-weight:bold;'],
  'error': ['%cerror:', 'color:red;font-weight:bold;'],
};

const colorsNode = {
  'debug': ['\x1b[33m%s\x1b[0m', 'debug:'],
  'info': ['\x1b[34m%s\x1b[0m', 'info:'],
  'warn': ['\x1b[35m%s\x1b[0m', 'warn:'],
  'error': ['\x1b[31m%s\x1b[0m', 'error:'],
};

const colors = typeof window === 'undefined' ? colorsNode : colorsBrowser;

export default {
  debug: log.debug.bind(log, ...colors.debug),
  info: log.info.bind(log, ...colors.info),
  warn: log.warn.bind(log, ...colors.warn),
  error: log.error.bind(log, ...colors.error),
  log: log.log,
  logger: log,
};
