export const FONTS_CDN_URL = {
  poppins: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap',
};

export enum FontTypeEnum {
  Poppins = 'poppins',
}

export const SUNBIT_LOGO = 'url(https://static.sunbit.com/sdk/assets/sunbit_logo.svg)';
export const SUNBIT_LOGO_WITH_TEXT = 'url(https://static.sunbit.com/sdk/assets/sunbit_logo_with_text.svg)';
export const SUNBIT_LOGO_WITH_TEXT_DARK = 'url(https://static.sunbit.com/sdk/assets/sunbit_logo_with_text_dark.svg)';
export const SUNBIT_ARROW_ICON = 'url(https://static.sunbit.com/sdk/assets/nextarrow.png)';
export const SUNBIT_THEME = {
  white: '#FFFFFF',
  deepBlue: '#002169',
  deepBlue35: '#596F9D',
  deepBlue50: '#8090B4',
  deepBlue85: '#D9DEE7',
  brightBlue: '#3156FF',
  brightBlue85: '#E0E6FF',
  deepBlue95: '#F2F4F7',
  brightBlue98:'#FBFCFF'
};

export function setStyle(dom: HTMLElement, styleProp: string, value: string) {
  if (!dom) {
    throw new Error('element not specified');
  }
  if (typeof styleProp !== 'string') {
    throw new Error('style must be a string');
  }

  styleProp = styleProp.replace(/-(\w)/g, function (_m, g1) {
    return g1.toUpperCase();
  });
  dom.style[styleProp] = value;
}

export function getStyle(dom: HTMLElement | Element, styleProp: string) {
  if (!dom) {
    throw new Error('element not specified');
  }
  if (typeof styleProp !== 'string') {
    throw new Error('style must be a string');
  }

  const _styleProp = styleProp.replace(/-(\w)/g, function (_m, g1) {
    return g1.toUpperCase();
  });

  const currentStyle = document.defaultView.getComputedStyle(dom, null);
  let computedStyle = currentStyle[_styleProp];

  if (/backgroundPosition?/.test(_styleProp) && /top|left/.test(computedStyle)) {
    computedStyle = '0%';
  }
  return computedStyle;
}

export function remove(elem: Element) {
  if (!elem || !elem.parentNode) {
    return null;
  } else {
    return elem.parentNode.removeChild(elem);
  }
}

export function html(dom: HTMLElement, content: string): HTMLElement {
  if (!(dom instanceof Element)) {
    throw new Error('element not specified');
  }
  if (typeof content !== 'string') {
    throw new Error('style must be a string');
  }

  try {
    dom.innerHTML = content;
    return dom.firstChild as HTMLElement;
  } catch (e) {
    throw new Error('Could not set innerHTML : ' + e.message);
  }
}

export function addCssRules(styles: string) {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.textContent = styles;
  document.getElementsByTagName('head')[0].appendChild(style);
}

export function containsCss(dom: HTMLElement, className: string) {
  if (!dom) {
    throw new Error('element not specified');
  }
  if (typeof className !== 'string') {
    throw new Error('style must be a string');
  }

  const cssClassWithSpace = ' ' + dom.className + ' ';
  return cssClassWithSpace.indexOf(' ' + className + ' ') >= 0;
}

export function addCss(dom: HTMLElement, className: string) {
  if (!dom) {
    throw new Error('element not specified');
  }
  if (dom == null) {
    return;
  }
  if (typeof className !== 'string') {
    throw new Error('style must be a string');
  }

  if (!containsCss(dom, className)) {
    dom.className = dom.className + ' ' + className;
  }
}

export function removeCss(dom: HTMLElement, className: string) {
  if (!dom) {
    throw new Error('element not specified');
  }
  if (dom == null) {
    return;
  }
  if (typeof className !== 'string') {
    throw new Error('style must be a string');
  }

  const regExp = new RegExp('\\s*' + className, 'g');
  dom.className = dom.className.replace(regExp, '').trim();
}

export function isElementInViewport(dom: HTMLElement) {
  if (!dom) {
    throw new Error('element not specified');
  }

  // const rect = dom.getBoundingClientRect();
  const computedStyles = window.getComputedStyle(dom);

  if (computedStyles.visibility === 'hidden' || computedStyles.display === 'none') {
    return false;
  }
  return true;

  // return (
  //     rect.top >= 0 &&
  //     rect.left >= 0 &&
  //     rect.width > 0 &&
  //     rect.height > 0 &&
  //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  // );
}

export function getElement(selector: string | HTMLElement, context: HTMLElement | Document = document) {
  return typeof selector === 'string' ? (context.querySelector(selector) as HTMLElement) : selector;
}

export function addFont(font: FontTypeEnum) {
  var link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('type', 'text/css');
  link.setAttribute('href', FONTS_CDN_URL[font]);
  document.getElementsByTagName('head')[0].appendChild(link);
}

export function defineHTMLElement(
  elementType: 'div' | 'span',
  elementId?: string,
  elementClass?: string,
  innerHTML?: string
): HTMLElement {
  const element: HTMLElement = document.createElement(elementType);
  if (elementId) {
    element.setAttribute('id', elementId);
  }
  if (elementClass) {
    element.setAttribute('class', elementClass);
  }
  if (innerHTML) {
    element.innerHTML = innerHTML;
  }
  return element;
}
