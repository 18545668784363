export default class Model {
    private propContainer = {};

    constructor(properties:{[x: string]: any}) {
        Object.keys(properties).forEach((name)=>{
            this.propContainer[name] = properties[name];

            this['set' + name] = (value) => {
                if (value === this.propContainer[name]) {
                    return this;
                }
                this.propContainer[name] = value;
                return this;
            };

            this['get' + name] = function () {
                return this.propContainer[name];
            };
        });
    }
} 