import RuntimeConfig from './Runtime';
import { apiClient as api } from './apiClient';
import Log from './Log';
export interface IUserAgentsResponse {
  mobile: () => boolean;
  mTouch: () => boolean;
  firefox?: () => number;
  ie?: () => number;
  chrome?: () => number;
  osx?: () => number;
  webkit?: () => number;
  edge?: () => number;
  iphone?: () => boolean;
  ipad?: () => boolean;
  android?: () => boolean;
}

export function userAgent(uas: string): IUserAgentsResponse {
  const devices = {
    iphone: /\b(iPhone|iP[ao]d)/.test(uas),
    ipad: /\b(iP[ao]d)/.test(uas),
    android: /Android/i.test(uas),
  };

  const mobile = /Mobile/i.test(uas);

  const versions = {
    ie: NaN,
    firefox: NaN,
    chrome: NaN,
    webkit: NaN,
    osx: '',
    edge: NaN,
  };

  const agent =
    /(?:MSIE.(\d+\.\d+))|(?:(?:Firefox|GranParadiso|Iceweasel).(\d+\.\d+))|(?:AppleWebKit.(\d+(?:\.\d+)?))|(?:Trident\/\d+\.\d+.*rv:(\d+\.\d+))/.exec(
      uas
    );

  if (agent) {
    versions.ie = agent[1] ? parseFloat(agent[1]) : agent[4] ? parseFloat(agent[4]) : NaN;
    versions.firefox = parseFloat(agent[2]) || NaN;
    versions.webkit = parseFloat(agent[3]) || NaN;

    if (agent[3]) {
      const chromeAgent = /(?:Chrome\/(\d+\.\d+))/.exec(uas);
      versions.chrome = chromeAgent ? parseFloat(chromeAgent[1]) : NaN;
      const edgeAgent = /(?:Edge\/(\d+\.\d+))/.exec(uas);
      versions.edge = edgeAgent ? parseFloat(edgeAgent[1]) : NaN;
    }
  }

  const mac = /(?:Mac OS X (\d+(?:[._]\d+)?))/.exec(uas);
  if (mac) {
    versions.osx = mac[1];
  }

  function getVersionParts(version) {
    return String(version)
      .split('.')
      .map(function (v) {
        return parseFloat(v);
      });
  }

  const userAgents = {
    mobile() {
      return devices.iphone || devices.ipad || devices.android || mobile;
    },
    mTouch() {
      return devices.android || devices.iphone || devices.ipad;
    },
  };

  Object.keys(versions).map(function (key) {
    userAgents[key] = function () {
      if (key === 'osx') {
        return parseFloat(versions[key].replace('_', '.'));
      }
      return parseFloat(versions[key]);
    };

    userAgents[key].getVersionParts = function () {
      return getVersionParts(versions[key]);
    };
  });

  Object.keys(devices).map(function (key) {
    userAgents[key] = function () {
      return devices[key];
    };
  });

  return userAgents;
}

export function addFuncToDomReady() {
  let queue: Array<{ (): void }>;
  const domIsReady = 'readyState' in document ? /loaded|complete/.test(document.readyState) : !!window.document.body;

  function flush() {
    if (!queue) {
      return;
    }

    let fn = queue.shift();
    while (fn !== void 0) {
      fn();
      fn = queue.shift();
    }
    queue = null;
  }

  if (!domIsReady) {
    queue = [];
    if (document.addEventListener) {
      window.document.addEventListener('DOMContentLoaded', flush, false);
      window.addEventListener('load', flush, false);
    } else if (window.document.attachEvent) {
      window.document.attachEvent('onreadystatechange', flush);
      window.attachEvent('onload', flush);
    }

    if (document.documentElement.doScroll && window === window.top) {
      const test = function () {
        try {
          window.document.documentElement.doScroll('left');
        } catch (_unused) {
          setTimeout(test, 0);
          return;
        }
        flush();
      };
      test();
    }
  }

  return function (fn: { (): void }) {
    if (queue) {
      queue.push(fn);
      return;
    } else {
      fn();
    }
  };
}

export function twoPt(value: number): string {
  const valueStr = '' + value;
  const split = valueStr.split('.');
  if (split.length === 1) {
    return valueStr + '.00';
  } else if (split.length > 2) {
    return valueStr;
  } else if (split[1].length === 1) {
    return valueStr + '0';
  } else if (split[1].length === 2) {
    return valueStr;
  } else {
    return [split[0], split[1].substr(0, 2)].join('.');
  }
}

export function setPriceFormat(price: number | '', addFraction: boolean = true): string {
  if (!price && price !== 0) {
    return '';
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: addFraction ? 2 : 0,
  }).format(price);
}

export const UA = userAgent(navigator.userAgent);

export const domReady = addFuncToDomReady();

export function guid() {
  // tslint:disable-next-line:no-bitwise
  return (Math.random() * (1 << 30)).toString(16).replace('.', '');
}

export function debounce(func: (...givenArgs: any[]) => any, wait: number, immediate?: boolean) {
  let timeout: NodeJS.Timeout;
  return function () {
    // tslint:disable-next-line:no-this-assignment
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}
let termsRequested = false;

export let waitForTerms: (fn: any) => void = function (fn) {
  if (termsRequested === false) {
    termsRequested = true;
    setWaitForTerms(fn);
  }
};

export function setWaitForTerms(cb?: any) {
  Log.debug('setWaitForTerms called');
  let queue: Array<() => void>;
  const termsLoaded = RuntimeConfig.getTerms() !== null;

  function flush() {
    if (!queue) {
      return;
    }

    let fn = queue.shift();
    while (fn !== void 0) {
      fn();
      fn = queue.shift();
    }
    queue = null;
  }

  if (!termsLoaded) {
    queue = cb ? [cb] : [];
    api('getTerms', {
      cb(error, json) {
        if (!error) {
          RuntimeConfig.setTerms(json);
          flush();
        }
      },
    });
  }

  return (waitForTerms = function (fn) {
    if (queue) {
      queue.push(fn);
      return;
    } else {
      fn();
    }
  });
}
