import { addParamsToUrl} from './queryString';
import Log from './Log';

export default class CORSRequest {
    private schemeRegex = new RegExp('^(http|https)://');
    private xhr:XMLHttpRequest;
    private stringifiedData = null;

    constructor(method, url, config) {
        if (!XMLHttpRequest) {
            throw new Error('no XMLHttpRequest found on window');
        }
        
        this.xhr = new XMLHttpRequest();

        if(!('withCredentials' in this.xhr)){
            throw new Error('XHR does not support CORS');
        }

        if (!this.schemeRegex.test(url)) {
          Log.error('URI must start with http or https');
          return null;
        }

        const allowedMethods = ['get', 'post', 'put', 'delete', 'options'];
        if (allowedMethods.indexOf(method.toLowerCase()) === -1) {
            throw new Error(method + ' Method not allowed');
          }

        
        // tslint:disable-next-line:no-empty
        const noop = function() {};

        if (Object.prototype.toString.call(config) !== '[object Object]') {
            config = {};
        }

        if (config.params) {
            url = addParamsToUrl(url, config.params);
        }

        this.xhr.open(method, url, true);
        if (config.headers) {
          Object.keys(config.headers).forEach((key)=> {
            this.xhr.setRequestHeader(String(key), String(config.headers[key]));
          });
        }

        if (method.toLowerCase() === 'post' || method.toLowerCase() === 'put') {
            if (config.data) {
              try {
                this.stringifiedData = JSON.stringify(config.data);
                this.xhr.setRequestHeader('Content-Type', 'application/json');
              } catch {
                throw new Error('Data is not a valid JSON');
              }
            }
          }

          let onload = () => {
            onload = noop;
            if ('onload' in config) {
              config.onload(this.xhr);
            }
          };
  
          let onerror = () => {
            onerror = noop;
            if ('onerror' in config) {
              config.onerror(this.xhr);
            }
          };
  
          this.xhr.onload = function() {
            onload();
          };
  
          this.xhr.onerror = function() {
            onerror();
          };
  
          this.xhr.onreadystatechange = () => {
            if (this.xhr.readyState === 4) {
              if (this.xhr.status === 200) {
                onload();
              } else {
                this.xhr.onload = noop;
                onerror();
              }
            }
          };
    }

    public send() {
        if (this.stringifiedData) {
            this.xhr.send(this.stringifiedData);
            return;
        }
        this.xhr.send();
    }

    public abort() {
      this.xhr.abort();
    }
}