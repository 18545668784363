import { apiClient, setApiUrl } from './apiClient';
import { Epay, setEpayUrl } from './epay/Epay';
import Log from './Log';
import { Modes } from './Modes';
import RuntimeConfig from './Runtime';
import UI from './UI';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    SUNBIT: any;
  }
}

interface IConfig {
  sunbitKey?: string;
  location?: string;
  representative?: string;
  representativeEmail?: string;
  ro?: string;
  locale?: string;
  mode?: Modes;
  v2?: boolean;
  onInitFinish?: () => void;
  isPSEnabled?: boolean;
}

try {
  if (!window.SUNBIT) {
    (function (window: any) {
      Log.debug('starting sdk');
      // const schemeRegex = new RegExp('^(http|https)://');

      // init
      window.setTimeout(function () {
        if (window.sunbitAsyncInit && !window.sunbitAsyncInit.hasRun) {
          window.sunbitAsyncInit.hasRun = true;
          window.sunbitAsyncInit();
        }
      }, 0);

      const epay = new Epay();

      function init(config: IConfig) {
        if (!config.sunbitKey || typeof config.sunbitKey !== 'string') {
          throw new Error('sunbitKey is missing or is not a string');
        }

        RuntimeConfig.setSunbitKey(config.sunbitKey);

        if (config.location && typeof config.location === 'string') {
          RuntimeConfig.setLocation(config.location);
        }

        if (config.ro && typeof config.ro === 'string') {
          RuntimeConfig.setRO(config.ro);
        }

        if (config.locale && typeof config.locale === 'string') {
          RuntimeConfig.setLocale(config.locale);
        }

        if (config.representative && typeof config.representative === 'string') {
          RuntimeConfig.setRepresentative(config.representative);
        }

        if (config.representativeEmail && typeof config.representativeEmail === 'string') {
          RuntimeConfig.setRepresentativeEmail(config.representativeEmail);
        }

        if (config.v2) {
          RuntimeConfig.setV2(config.v2);
        }

        if (config.isPSEnabled) {
          window.SUNBIT.initPSModule = new Promise(function (resolve) {
            import('./ps/PS')
              .then((prescreen) => {
                prescreen.init();
                resolve(prescreen);
              })
              .catch((err) => {
                throw new Error(`Unable to load Pre-screen module:${err.message} `);
              });
          });
        }

        if (
          config.mode &&
          typeof config.mode === 'string' &&
          [Modes.PRODUCTION_MODE, Modes.SANDBOX_MODE, Modes.DEMO_MODE].indexOf(config.mode) > -1
        ) {
          RuntimeConfig.setMode(config.mode);
        }

        RuntimeConfig.setInitRan(true);

        epay.init({ onInitFinish: config.onInitFinish });
      }

      window.SUNBIT = {
        init,
        api: apiClient,
        setApiUrl,
        UI,
        epay,
        setEpayUrl,
        version: RuntimeConfig.getVersion(),
      };
    })(window);
  }
} catch (e) {
  Log.error('an error ocurred', e.message);
}
