import RuntimeConfig from './Runtime';
// tslint:disable:max-line-length
const strings = {
  'en-us': {
    disclaimerA:
      'Based on :duration: monthly payments, APR :nominal:% and a :downPayment:% down payment of the purchase price including tax. Subject to approval. APR, down payment and other terms displayed here are an example only. Actual approval amount, APR and required down payment varies based on creditworthiness. APR ranges from 9.99% to 35.99%. Any financing offered is made by Transportation Alliance Bank, Inc., dba TAB Bank, which determines qualifications for and terms of credit.',
    disclaimerB:
      'The monthly payment amount(s), interest rates, and any financing related information displayed on this Site are estimates only (“Estimate”). This Estimate is being provided to you only as a guide to potential financing options that may be available. It is not a loan application, an offer or guarantee of financing, or a loan approval. Any financing offered is made by Transportation Alliance Bank, Inc., dba TAB Bank, which determines qualifications for and terms of credit, and is processed through Sunbit, Inc. technology and not by the COMPANY_NAME dealership or any of their affiliated business entities. Neither Sunbit, Inc. nor TAB Bank is an affiliate of COMPANY_NAME or Dealership.',
    asLowAs: 'As Low As',
    perMonthWith: '/month with',
    checkFinancing: 'Check financing options',
    checkoutWith: 'Pay monthly with',
    maxApprovalAmount_customer_learnMore: 'Click to view your offer',
    maxApprovalAmount_customer_title_accept:
      'You\'re pre-approved to finance a purchase of up to <span class="sunbit-font-weight-600">:amount:*</span> with <span class="sunbit-font-weight-600">Sunbit</span>',
    maxApprovalAmount_merchant_title_accept:
      ':customerName: has been Pre-approved for <span class="sunbit-font-weight-600">:amount:</span> for financing with <span class="sunbit-font-weight-600">Sunbit!</span> Details have been sent to them.',
    maxApprovalAmount_merchant_title_decline:
      "Don't forget to send :customerName: a link to prequalify for financing with Sunbit.",
    maxApprovalAmount_customer_title_decline:
      ':customerName:, prequalify for financing to buy now, pay over time with Sunbit',
    maxApprovalAmount_customer_prequalNow: 'Click to prequalify now',
    maxApprovalAmount_customer_disclaimer:
      '*This amount includes a payment due at checkout. :retailerName: has been informed of this offer.',
    estimatedAmount_tooltip_disclaimer_small:
      '* This amount includes a payment due at checkout. :retailerName: has been informed of this offer. 0-:maxNominal:% APR. Loan terms vary based on your state of residence, your outstanding loans through Sunbit, the participating retailer, and other factors. Loan and payment activity are reported to a major credit bureau. Loans made by Transportation Alliance Bank, Inc., dba TAB Bank, which determines qualifications for and terms of credit.<br/>' +
      '^ This estimate includes a payment due at checkout. Example based on :duration: monthly payments, a :nominal:% APR, and a :downPayment: payment at checkout of the purchase price including tax. Actual terms will vary.',
    estimatedAmount_tooltip_disclaimer_large:
      '* This amount includes a payment due at checkout. :retailerName: has been informed of this offer. 0-:maxNominal:% APR. Loan terms vary based on your state of residence, your outstanding loans through Sunbit, the participating retailer, and other factors. Loan and payment activity are reported to a major credit bureau. Loans made by Transportation Alliance Bank, Inc., dba TAB Bank, which determines qualifications for and terms of credit.<br/>' +
      '^ The last payment may vary slightly.',
    estimatedAmount_tooltip_disclaimer_decline:
      '* Based on :duration: monthly payments, :nominal:% APR, :estimatedAmount: purchase price including tax, and a :downPayment: payment at checkout of the purchase price including tax. Actual terms will vary. Subject to approval based on creditworthiness. A payment is due at checkout. APR ranges from 0% to :maxNominal:%. Any financing offered is made by Transportation Alliance Bank, Inc., dba TAB Bank, which determines qualifications for and terms of credit.',
    estimatedAmount_tooltip_confirm: 'Got it.',
    estimatedAmount_large_version_title:
      '<span class="sunbit-font-weight-600">You\'re pre-approved to finance a purchase of up to <span class="sunbit-font-weight-800">:amount:*</span></span>',
    estimatedAmount_large_version_totalEstimated:
      '<span class="sunbit-font-weight-600">Estimated purchase price: <span class="sunbit-font-weight-800">:amount:</span></span>',
    estimatedAmount_large_version_paymentsOf: 'Payments of',
    estimatedAmount_large_version_zeroAPR: ':nominal:% APR',
    estimatedAmount_small_version_title:
      'You\'re pre-approved to finance a purchase of up to <span class="sunbit-font-weight-600">:amount:*</span>',
    estimatedAmount_small_version_asLowAs:
      'You could pay as low as :minPaymentAmount:/month for you purchase of :totalAmount:<span class="amount-symbol">^</span> <br/> This estimate includes a payment due at checkout',
    estimatedAmount_small_version_learnMore: 'Click to learn more',
    estimatedAmount_failed_asLowAs: 'Pay as low as :minPaymentAmount:/month*',
    estimatedAmount_failed_title:
      ':customerName:, click here to prequalify for financing to buy now,<br/> pay over time with Sunbit',
    estimatedAmount_failed_prequalNow: 'Click to prequalify now',
  },
  'te-st': {
    checkFinancing: 'test string',
  },
};

export default function (key: string): string {
  let locale = RuntimeConfig.getLocale();
  const defaultLocale = 'en-us';
  if (!strings[locale]) {
    locale = defaultLocale;
  }
  let value = strings[locale][key];
  if (!value) {
    value = strings[defaultLocale][key];
    if (!value) {
      return '';
    }
  }
  return value;
}
